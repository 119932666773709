.wrapper {
  width: 100px;
  height: 100px;
  position: relative;

  .add {
    transition: all 0.6s;
    height: 100px;
    width: 100px;
    background-color: #00000069;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
  }
  &:hover .add {
    opacity: 1;
  }
}
