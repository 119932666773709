.wrapper {
  height: 100px;
  width: 100px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;

  .add {
    transition: all 0.6s;
    height: 100%;
    width: 100%;
    background-color: #00000069;
    position: absolute;
    z-index: 2;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
  }

  .preview {
    height: 100%;
    width: 100%;
  }

  &:hover .add {
    opacity: 1;
  }
}
