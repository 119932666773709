.items {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}
.listItem {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #000;
}
