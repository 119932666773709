$field_for_cards: 15px;
$wrapper_bg_color: #f5f5f5;
$card_bg_color: #fbfbfb;

.stage-wrapper {
  background-color: $wrapper_bg_color !important;
  height: calc(100vh - 4.375rem - 68px);
  overflow: hidden;
  .stage-right {
    height: 100%;
    &__row {
      height: 100%;
      .stage-list {
        height: 100%;
        overflow: auto;
        .stage-item {
          background-color: $card_bg_color;

          cursor: pointer;
          &:not(:first-child) {
            margin-top: $field_for_cards;
          }
          &:last-child {
            margin-bottom: 150px;
          }
          border-radius: 30px;
          &__text {
            font-size: 13px;
          }

          &.selected-item {
            border: 1px solid #dfbe03;
          }
        }
      }
    }
  }

  .modules {
    border-radius: 30px 0 0 30px;
    background-color: $card_bg_color;
    height: 100%;
    overflow: auto;
    &__text {
      font-size: 13px;
      font-weight: 600;
      max-width: 350px;
    }

    &__list {
      &:last-child {
        margin-bottom: 150px;
      }
      &-item {
        &:not(:first-child) {
          margin-top: $field_for_cards;
        }

        &:last-child {
          margin-bottom: 30px;
        }

        padding: 20px;
        background-color: #fff;
        box-shadow: 0 0 1px rgb(103, 102, 102);
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}
