// .stickyCard {
//   position: sticky;
//   top: 50px;
//   right: 0px;
//   left: 20px;
//   width: 700px;
//   border-radius: 20px;
//   float: right;
//   overflow: hidden;
// }

// .list {
//   margin-left: 50px;
//   margin-bottom: 20px;
//   li {
//     list-style-type: disc;
//   }
// }
// @media only screen and (max-width: 768px) {
//   .stickyCard {
//     width: 300px;
//   }
// }

.detailed-course-wrapper {
  display: grid;
  grid-template-columns: 1.8fr 1fr;
  gap: 10px;
  .main-page {
    width: 100%;

    .main {
      padding: 3px;
      border: 1px solid #e0dddd;
      border-radius: 10px;
      margin-bottom: 20px;
      &-image {
        height: 400px;
        width: 100%;
        border-radius: inherit;
      }
    }

    .about-course {
      padding: 10px;
      border: 1px solid #e0dddd;
      border-radius: 10px;

      .video-frame {
        iframe {
          width: 100%;
        }
      }
    }

    .lesson {
      padding: 10px 18px;
      &:nth-child(even) {
        background-color: #e8e8fe;
      }
    }

    .instructor {
      margin-bottom: 20px;
      .inside {
        display: flex;
        gap: 40px;
        align-items: center;
        .avatar {
          height: 150px;
          width: 150px;
          border-radius: 20px;
          object-fit: cover;
        }
      }
    }
  }

  .card-sticky {
    position: sticky;
    top: 0;
    height: 100px;
    // background-color: #5be55d;
    width: 100%;
    .price-card {
      padding: 3px 10px;
      border: 1px solid #e0dddd;
      border-radius: 10px;
      color: #000;

      .item {
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        p {
          margin: 0;
        }
      }
      .purchase-button {
        all: unset;
        text-align: center;
        border-radius: 5px;
        background-color: #4dca88;
        padding: 2px 0;
        width: 100%;
        cursor: pointer;
        transition: 0.15s;
        &:hover {
          background-color: #157347;
        }
      }

      .purchase-button:disabled {
        background-color: #9bd0b4;
        color: #878585;
      }
    }
  }
}
