.wrapper {
  height: 500px;
  width: 100%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  .add {
    transition: all 0.6s;
    height: 100%;
    width: 100%;
    background-color: #00000069;
    position: absolute;
    z-index: 2;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
  }

  .preview {
    height: 100%;
    width: 100%;
  }

  &:hover .add {
    opacity: 1;
  }
}

.video {
  iframe {
    width: 100% !important;
    height: 100% !important;
    #player {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
