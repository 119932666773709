.react-datepicker__input-container input {
  width: 100%;
}
.calendar-button-wrapper {
  display: flex;
  flex-direction: column;
  .calendar-button-wrapper__buttons-first {
    display: flex;
    justify-content: space-around;
  }
  .calendar-button-wrapper__buttons-second {
    display: flex;
    justify-content: space-between;
  }

  button {
    height: 50px;
    width: 50px;
    border: none;
    border-radius: 50%;
    background-color: #00f;
    color: white;
    border: 4px solid #00f;
    transition: 0.5s;
  }
  .button-selected {
    border: 4px solid #0f0;
  }

  button:disabled {
    background-color: #707072;
    border: 4px solid #707072;
  }
}
.input-wrapper:not(:first-child) {
  margin-top: 10px;
}

.form-inside {
  position: "absolute";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  box-shadow: 24;
  min-width: 700px;
  .inputs {
    display: flex;
    align-items: end;
    gap: 5px;
  }
}
