.loginBtn {
  all: unset;
  width: 21.875rem;
  height: 3.125rem;
  color: #fff;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 14px;
  background-color: blue;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  transition: all 0.4s linear;
  box-sizing: content-box;
  border: 1px solid #fff;
  margin: auto;
  &:hover {
    background-color: #fff;
    border-color: blue;
    color: blue;
  }
}
