* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.role {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  input {
    margin: 0 5px;
    cursor: pointer;
  }
  label {
    cursor: pointer;
  }
}

.registerFooter {
  width: 21.875rem !important;
  p {
    font-size: 1rem !important;
    a {
      font-size: 1rem !important;
    }
  }
}

.parentLogin {
  width: 100%;
  height: 100vh;
  display: flex;
  .loginLeft {
    height: 100vh;
    width: 50%;
    background: linear-gradient(
        0deg,
        rgba(52, 52, 255, 0.5),
        rgba(52, 52, 255, 0.5)
      ),
      url(https://codekaplan.com/static/media/auth-bg.96304eedbb8e1a4d74d5.png);
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      svg {
        width: 400px;
        height: 111px;
      }
    }
    @media (max-width: 950px) {
      display: none;
    }
  }
  .loginRight {
    height: 100vh;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px auto;

    h1 {
      font-size: 2rem;
      font-weight: 700;
    }
    p,
    a {
      font-size: 1.1rem;
    }
    .paragraph {
      width: 20rem !important;
      margin: 20px auto;
    }
    .loginForm {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      height: 21.25rem;

      .loginInput {
        border: 1px solid #eee;
        border-radius: 6px;
      }

      .input {
        all: unset !important;
        display: inline-block;
        width: 21.875rem !important;
        padding: 12px !important;
        color: #777 !important;
        background-color: #fff !important;
        border-radius: 6px !important;
      }

      .loginBtn {
        all: unset;
        width: 21.875rem;
        height: 3.125rem;
        color: #fff;
        font-weight: 600;
        display: inline-block;
        padding: 0 14px;
        background-color: blue;
        border-radius: 6px;
        text-align: center;
        cursor: pointer;
        transition: all 0.4s linear;
        box-sizing: content-box;
        border: 1px solid #fff;
        &:hover {
          background-color: #fff;
          border-color: blue;
          color: blue;
        }
      }

      .loginBtnDisabled {
        opacity: 0.3;
        cursor: auto;
        &:hover {
          opacity: 0.3;
          background-color: blue;
          border: 1px solid #fff;
          color: #fff;
        }
      }

      .labels {
        display: flex;
        // justify-content: space-between;
        justify-content: space-evenly;

        label {
          margin: 0 !important;
          display: flex;
          align-items: center;
          cursor: pointer;
          input {
            margin-left: 10px;
          }
        }
      }
    }

    @media (max-width: 950px) {
      width: 100%;
    }
  }
}

@media (max-width: 400px) {
  html {
    font-size: 12px;
  }
}
