@import url("https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap");
.outer {
  border: 2px solid #dbdbdb;
  border-radius: 10px;
  padding: 20px;
  .wrapper {
    border: 1px solid #fff;

    .head {
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .startDate {
          color: red;
          font-weight: bold;
        }
      }
      .bottom {
        text-align: center;
        font-weight: bold;
      }
    }

    .display {
      height: 300px;
      width: 100%;
      background-color: #1d314f;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .title {
        font-family: "Share Tech Mono", monospace;
        color: #fff;
      }
    }

    .description {
      text-align: center;
      margin-top: 20px;
    }
    &:not(:first-child) {
      margin-top: 60px;
      border-top: 1px solid #3434ff;
      padding-top: 20px;
    }
  }
}
