.search-content {
  width: 500px;
  max-height: 500px;
  padding: 16px;
  background-color: #eee;
  overflow: auto;
  .search-item {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 20px;
    cursor: pointer;
    &-image {
      height: 50px;
      width: 50px;
    }
    &-description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
  }
}
