.wrapper {
  font-size: 75px;
  display: flex;
  align-items: center;
  height: 100px;
  width: 550px;
  @media (max-width: 1200px) {
    width: 300px;
    font-size: 40px;
    height: 50px;
  }
  @media (max-width: 420px) {
    width: 200px;
    font-size: 30px;
    height: 40px;
  }
  .time {
    display: inline-block;
    position: relative;
    height: 100%;
    width: 30%;

    .top {
      position: absolute;
      height: 49.3%;
      background-color: #1baa76;
      width: 100%;
      top: 0;
      border-radius: 10px;
      .left {
        position: absolute;
        height: 3px;
        width: 3px;
        border-radius: 50%;
        background-color: #1d314f;
        bottom: 5px;
        left: 5%;
      }
      .right {
        position: absolute;
        height: 3px;
        width: 3px;
        border-radius: 50%;
        background-color: #1d314f;
        bottom: 5px;
        right: 5%;
      }
    }

    .bottom {
      position: absolute;
      width: 100%;
      height: 49.2%;
      background-color: #1baa76;
      bottom: 0;
      border-radius: 10px;

      .left {
        position: absolute;
        height: 3px;
        width: 3px;
        border-radius: 50%;
        background-color: #1d314f;
        top: 5px;
        left: 5%;
      }
      .right {
        position: absolute;
        height: 3px;
        width: 3px;
        border-radius: 50%;
        background-color: #1d314f;
        top: 5px;
        right: 5%;
      }
    }
    span {
      font-size: 95%;
      position: absolute;
      z-index: 999;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
    }
  }
}
