.wrapper {
  display: flex;
  align-items: start;
  margin-bottom: 15px;
  gap: 10px;
  background-color: #f8f9fa;
  padding: 20px;
  label {
    cursor: pointer !important;
  }
  .list {
    margin-left: 30px;
    li {
      list-style-type: disc;
    }
  }
}
